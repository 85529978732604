import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router";
import { useGetUsersQuery, useSetAdminLevelMutation, useRemoveUserMutation } from "../../store/slices/apiSlice";
import styles from "./UsersDatatable.module.css";
import { Toast } from "primereact/toast";

export default function UsersDatatable() {
  const navigate = useNavigate();
  const cm = useRef(null);
  const toast = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [adminDialogVisible, setAdminDialogVisible] = useState(false);
  const { data: users = [], isLoading } = useGetUsersQuery();
  const [setAdminLevel] = useSetAdminLevelMutation();
  const [removeUser] = useRemoveUserMutation();

  const [globalFilter, setGlobalFilter] = useState(""); // Состояние для поиска
  const [filteredUsers, setFilteredUsers] = useState([]);
  const statuses = [
    { name: "—", code: "NONE" },
    { name: "Пользователь", code: "ACTIVE" },
    { name: "Вип", code: "VIP" },
    { name: "Бизнес", code: "BIZ" },
  ];

  const phoneBodyTemplate = (user) => {
    return <label>{getPhone(user.phoneNumber.replace(/[^\d]/g, ""))}</label>;
  };

  const getPhone = (phone) => {
    return (
      "+" +
      phone.slice(0, 1) +
      " (" +
      phone.slice(1, 4) +
      ") " +
      phone.slice(4, 7) +
      "-" +
      phone.slice(7, 9) +
      "-" +
      phone.slice(9, 11)
    );
  };

  const menuModel = [
    {
      label: "Редактировать",
      icon: "pi pi-pencil",
      command: () => {
        navigate(`./edit/${selectedRow.id}`);
      },
    },
    {
      label: "Удалить",
      icon: "pi pi-fw pi-times",
      command: () => {
        setConfirmationVisible(true);
      },
    },
    {
      label: "Назначить администратором",
      icon: "pi pi-user-plus",
      command: () => {
        setAdminDialogVisible(true);
      },
    },
  ];

  const handleContextMenu = (event) => {
    setSelectedUser(event.data);
    setSelectedRow(event.data); // Устанавливаем выбранную строку
    cm.current.show(event.originalEvent); // Показываем контекстное меню
  };

  const confirmationFooter = (
    <div>
      <Button
        label="Отмена"
        outlined
        className={styles.custom_btn_outlined}
        onClick={() => setConfirmationVisible(false)}
      />
      <Button
        label="Подтвердить"
        className={ styles.custom_btn }
        onClick={ () => {
          handleRemoveUser();
          setConfirmationVisible(false)
        }}
      />
    </div>
  );

  const statusTemplate = (row) => {
    return <p>{statuses.find((s) => s.code === row.participantStatus)?.name}</p>;
  };

  const adminTemplate = (row) => {
    let role = "Пользователь";
    if (row.adminLevel > 0)
      role = 'Модератор';
    if (row.adminLevel >= 5)
      role = 'Администратор'
    if (row.adminLevel >= 6)
      role = 'Гл. Админ.';

    return <p>{role}</p>;
  };

  const handleSetAdminLevel = async (level) => {
    try {
        await setAdminLevel({ id: selectedUser.id, adminLevel: level }).unwrap();
        toast.current.show({
            severity: "success",
            summary: "Успех",
            detail: `Пользователь назначен с уровнем ${level}`,
            life: 3000,
        });
      setAdminDialogVisible(false);
      setTimeout(() => navigate(0), 500);
    } catch (error) {
        console.error("Ошибка: ", error); // Выводим ошибку в консоль
        toast.current.show({
            severity: "error",
            summary: "Ошибка",
            detail: "Произошла ошибка при назначении уровня администратора",
            life: 3000,
        });
    }
  };
  
  const handleRemoveUser = async () => {
    try {
        await removeUser({ id: selectedUser.id}).unwrap();
        toast.current.show({
            severity: "success",
            summary: "Успех",
            detail: `Пользователь удалён`,
            life: 3000,
        });
      setAdminDialogVisible(false);
      setTimeout(() => navigate(0), 1000);
    } catch (error) {
        console.error("Ошибка: ", error); // Выводим ошибку в консоль
        toast.current.show({
            severity: "error",
            summary: "Ошибка",
            detail: "Произошла ошибка при назначении уровня администратора",
            life: 3000,
        });
    }
  };

  useEffect(() => {
    if (globalFilter) {
        const filters = globalFilter.toLowerCase().split(" ").filter(Boolean); // Разбиваем строку поиска на слова и убираем пустые строки

        const filteredUsers = users.filter((user) => {
            return filters.every(filter =>
                (`${user.lastName} ${user.firstName} ${user.secondName}`.toLowerCase().includes(filter) || // Поиск по имени и фамилии
                 user.phoneNumber?.toLowerCase().includes(filter) || // Поиск по телефону
                 user.email?.toLowerCase().includes(filter) || // Поиск по email
                 user.company?.toLowerCase().includes(filter)) // Поиск по компании
            );
        });

        setFilteredUsers(filteredUsers); // Обновляем состояние с отфильтрованными пользователями
    } else {
        setFilteredUsers(users); // Если нет фильтра, показываем всех
    }
}, [globalFilter, users]);

  return (
    <div className={styles.wrapper}>
      <Toast ref={toast} />
      <div className={ styles.searchContainer }>
          <InputText 
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)} 
          placeholder="Поиск..." 
          className={styles.searchInput}
        />
      </div>
      <ContextMenu
        model={menuModel}
        ref={cm}
        onHide={() => setSelectedRow(null)}
        style={{ width: "18rem" }}
      />
      <DataTable
        value={filteredUsers}
        scrollable
        removableSort
        scrollHeight="67vh"
        className={styles.datatable}
        selectionMode="single"
        selection={selectedRow}
        onSelectionChange={(e) => setSelectedRow(e.value)}
        onContextMenuSelectionChange={(e) => setSelectedRow(e.value)}
        onContextMenu={handleContextMenu}
        loading={ isLoading }
        
      >
        <Column field="lastName" header="ФИО" body={(rowData) => `${rowData.lastName} ${rowData.firstName} ${rowData.secondName}`} sortable />
        <Column field="phoneNumber" header="Телефон" body={phoneBodyTemplate} />
        <Column field="email" header="Почта" />
        <Column field="company" header="Компания" />
        <Column field="participantStatus" header="Статус" body={statusTemplate} sortable />
        <Column field="adminLevel" header="Роль" body={adminTemplate} sortable />
      </DataTable>

      <Dialog
        header="Удалить пользователя"
        footer={confirmationFooter}
        visible={confirmationVisible}
        onHide={() => setConfirmationVisible(false)}
      >
        <p>Вы уверены, что хотите удалить пользователя?</p>
      </Dialog>

      <Dialog
        header="Назначить администратором"
        visible={adminDialogVisible}
        onHide={() => setAdminDialogVisible(false)}
        footer={
          <div className={styles.wrapper}>
             <Button
              label="Пользователь"
              className={styles.custom_btn_outlined}
              onClick={() => handleSetAdminLevel(0)}
            />
            <Button
              label="Модератор"
              className={styles.custom_btn_outlined}
              onClick={() => handleSetAdminLevel(1)}
            />
            <Button
              label="Администратор"
              className={styles.custom_btn}
              onClick={() => handleSetAdminLevel(5)}
            />
          </div>
        }
      >
        <p>Выберите уровень прав для пользователя:</p>
      </Dialog>
    </div>
  );
}
