import React from "react";
import { Link, useNavigate } from "react-router-dom/dist";
import user from "../../images/user-light.svg";
import telegram from "../../images/telegram-light.svg";
import government from "./images/tyumen-obl-government.png";
import minstroy from "./images/minstroy.png";
import brusnika from "./images/brusnika-light.svg";

import industrialCluster from "./images/industrial-cluster.svg";
import wonder from "./images/wonder.svg";
import styles from "./Footer.module.css";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className={styles.footer} id="footer">
      {/*----1st-block------*/}
      <div className={styles.container}>
        <div className={styles.full_width}>
          <div className={styles.justify}>
            <div className={styles.row}>
              <Link>
                <img
                  alt="telegram"
                  src={telegram}
                  className={styles.icons}
                  onClick={() =>
                    window.open("https://t.me/gradforum", "_blank")
                  }
                />
              </Link>
              <Link to="/profile">
                <img src={user} className={styles.icons} />
              </Link>
            </div>

            <div className={ styles.column }>
              <Link to={"/#main"}>главная</Link>
              <Link to={ "/registration" }>регистрация</Link>
              <Link to="/program">программа</Link> 
            </div>

            <div className={styles.column}>
              <Link to={"/#mission"}>миссия</Link>
              <Link to={ "/#platform" }>площадка</Link>
              <Link to={ "/#exhibition-area" }>экспозиционная зона</Link>
            </div>

            <div className={ styles.column }>
              <Link to="https://forms.yandex.ru/u/66e27b1349363917fe558324/"
                target="_blank" rel="noopener noreferrer"  
                >B2B</Link>
              <Link to="/#speakers">спикеры</Link>
              <Link to="/#partners">партнеры</Link>
            </div>

            <div className={ styles.column }>
              <Link to="/urban-tour">урбан-туры</Link>
              <Link to="/city-guests-info">для гостей города</Link>
              <Link>путеводитель</Link>
            </div>

            <div className={ styles.column }>
              <Link to="https://forms.yandex.ru/u/66b46d91c417f325dde2f5fa/">для СМИ</Link>
              <Link to="/#qna">вопрос-ответ</Link>
              <Link to="https://t.me/gradforum_bot"
                target="_blank" rel="noopener noreferrer"  
                >помощь</Link>
            </div>
          </div>

          {/*-----mobile-block-----*/}
          <div className={styles.mobile_column}>
            <div className={styles.row}>
              <Link>
                <img
                  alt="telegram"
                  src={telegram}
                  className={styles.icons}
                  onClick={() =>
                    window.open("https://t.me/gradforum", "_blank")
                  }
                />
              </Link>
              <Link to="/login">
                <img alt="profile" src={user} className={styles.icons} />
              </Link>
            </div>

            <div className={styles.justify_mobile_row}>
              <div className={styles.column}>
              <Link to={"/#main"}>главная</Link>
              <Link to={ "/registration" }>регистрация</Link>
              <Link to="/program">программа</Link> 
              <Link to={"/#mission"}>миссия</Link>
              <Link to={ "/#platform" }>площадка</Link>
              <Link to={ "/#exhibition-area" }>экспозиционная зона</Link>
              <Link to="https://forms.yandex.ru/u/66e27b1349363917fe558324/"
                target="_blank" rel="noopener noreferrer"  
                >B2B</Link>
                <Link to="/#speakers">спикеры</Link>
              </div>

              <div className={styles.column}>
                <Link to="/#partners">партнеры</Link>
                <Link to="/urban-tour">урбан-туры</Link>
                <Link to="/city-guests-info">для гостей города</Link>
                <Link>путеводитель</Link>
                <Link to="https://forms.yandex.ru/u/66b46d91c417f325dde2f5fa/">для СМИ</Link>
                <Link to="/#qna">вопрос-ответ</Link>
                <Link to="https://t.me/gradforum_bot"
                  target="_blank" rel="noopener noreferrer"  
                  >помощь</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*---contacts---*/}
      <div className={styles.bordered_column}>
        <div className={styles.contacts}>
          <div className={styles.contact_box}>
            <div className={styles.label_break}>
              <label className={styles.uppercase}>Программный</label>
              <label className={styles.uppercase}>директор</label>
            </div>
            <div className={styles.contact_column}>
              <label className={styles.font_name}>Шухрат Маматахунов</label>
              <label className={ styles.tel }>
                <a href="tel:+79829674272" className="no_style_link">+7 982 967 42 72</a>
              </label>
              <label className={styles.email}>
                <a href="mailto:4U  @gradforum.ru" className="no_style_link">4U@GRADFORUM.RU</a>
              </label>
            </div>
          </div>

          <div className={styles.contact_box}>
            <div className={styles.label_break}>
              <label className={styles.uppercase}>Коммерческий</label>
              <label className={styles.uppercase}>директор</label>
            </div>
            <div className={styles.contact_column}>
              <label className={styles.font_name}>Никита Поваров</label>
              <label className={ styles.tel }>
                <a href="tel:+79523494985" className="no_style_link">+7 952 349 49 85</a>
              </label>
              <label className={styles.email}>
                <a href="mailto:partner@gradforum.ru" className="no_style_link">PARTNER@GRADFORUM.RU</a>
              </label>
            </div>
          </div>

          <div className={styles.contact_box}>
            <div className={styles.label_break}>
              <label className={styles.uppercase}>PR</label>
            </div>
            <div className={styles.contact_column_full}>
              <label className={styles.font_name}>Дарья Севрюгина</label>
              <label className={styles.tel}>
                <a href="tel:+79504954575" className="no_style_link">+7 950 495 45 75</a>
              </label>
              <label className={styles.email}>
                <a href="mailto:info@gradforum.ru" className="no_style_link">INFO@GRADFORUM.RU</a>
              </label>
            </div>
          </div>
        </div>
      </div>


      {/*----2nd-block------*/}
      <div className={styles.bordered_column_sponsors}>
        <div className={styles.mobile_img_row}>
          <div className={styles.column_gap_2}>
            <label className={styles.uppercase_500}>Организатор</label>
            <div className={ styles.imgs_row }>
              <Link to="https://admtyumen.ru/"
                target="_blank" rel="noopener noreferrer">
                  <div className={ styles.img_row }>
                    <img
                      src={government}
                      className={styles.imgs}
                      alt="Правительство Тюменской области"
                    />
                    <div className="only_desktop">
                      <div className={styles.label_break}>
                        <label className={styles.font_normal}>Правительство Тюменской</label>
                        <label className={styles.font_normal}>области в лице Главного </label>
                        <label className={styles.font_normal}>управления строительства</label>
                      </div>
                    </div>

                    <div className="only_mobile">
                      <div className={ styles.label_break }
                        style={ {
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          height: '100%'
                        } }>

                        <label className={styles.font_normal}>Правительство</label>
                        <label className={styles.font_normal}>ТО в лице ГУС</label>
                      </div>
                    </div>
                  </div>
              </Link>
            </div>
          </div>

          <div className={styles.img_mobile}>
            <label className={styles.uppercase_500}>Оператор</label>
            <div className={styles.img_row}>
              <img src={wonder} alt="Wonder" />
            </div>
          </div>
        </div>

        {/* вторая строка */}
        <div className={styles.mobile_img_row}>
          <div className={styles.column_gap_2}>
            <label className={styles.uppercase_500}>При поддержке</label>
              <div className={styles.imgs_row}>
                <Link to="https://minstroyrf.gov.ru/"
                  target="_blank" rel="noopener noreferrer">
                  <div className={ styles.img_row_align_center }>
                    <img
                        src={minstroy}
                        className={styles.imgs}
                        alt="Минстрой России"
                      />
                    <div className={styles.label_break}>
                      <label className={styles.font_normal}>Минстрой</label>
                      <label className={styles.font_normal}>России</label>
                      </div>
                    </div>
                </Link>
              </div>
          </div>

          <div className={styles.img_mobile}>
            <label className={styles.uppercase_500}>Соорганизаторы</label>
            <div className={styles.img_row}>
              <div className={styles.two_imgs_row}>
              <div className={styles.img_row_align_center}>
                  <img
                    src={brusnika}
                    className={styles.imgs}
                    alt="Брусника"
                    style={ { height: "4rem", marginTop: "0.5 rem" } }
                  />
                <div className={styles.label_break}>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        
       
        <div className={styles.container_no_border_desktop}>
          <label className={styles.uppercase_500}>Соорганизаторы</label>
          <div className={styles.two_imgs_row}>
            <div className={styles.img_row_align_center}>
                <img
                  src={brusnika}
                  className={styles.imgs}
                  alt="Брусника"
                  style={ { height: "4rem", marginTop: "0.5 rem" } }
                />
              <div className={styles.label_break}>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.container_no_border_desktop}>
          <label className={styles.uppercase_500}>Оператор</label>
          <div className={styles.align_end}>
            <img src={wonder} alt="Wonder" />
          </div>
        </div>
      </div>

      {/*----3rd-block------*/}
      <div className={styles.bordered_row}>
        <div className={styles.row_desktop}>
          <div className={styles.row}>
            <Link
              className={styles.pdf_links}
              to="/documents/Реквизиты оператора форума.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Реквизиты
            </Link>
            <Link
              className={styles.pdf_links}
              to="/documents/Публичная_оферта.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Публичная оферта
            </Link>
          </div>
          <Link
            className={styles.pdf_links}
            to="/documents/Политика_конфиденциальности_и_обработки_персональных_данных.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Политика конфиденциальности персональных данных
          </Link>
        </div>
      </div>

      {/*----4th-block------*/}
      <div className={styles.bordered_row}>
        <label className={styles.font_weight_300}>
          © Tyumen GradForum, 2023-2024
        </label>
      </div>
    </div>
  );
}

export default Footer;
