import React, { useEffect, useState } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import EventsCalendar from "../../components/EventsCalendar/EventsCalendar";
import SocialButtons from "../../components/SocialButtons/SocialButtons";
import styles from "./Program.module.css";
import { useGetQuery } from "../../store/slices/apiSlice";
import { useSelector } from "react-redux";
import { url } from "../../store/slices/apiSlice";
import { Speakers } from "../SpeakerPage/Speakers";
import Loader from "../../components/Loader/Loader";

export default function Program() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const profileEP = useSelector((state) => state.endpoints.profile);
  const { data: eventsData, isLoading, isError } = useGetQuery(`${url}/events`, {
    refetchOnMountOrArgChange: true,
  });

  const [events, setEvents] = useState([]);
  const [formatedDates, setFormatedDates] = useState([]);

  useEffect(() => {
    if (eventsData) {
      const formattedEvents = eventsData.map((event) => ({
        id: event.id,
        name: event.title,
        descr: event.content,
        date: new Date(event.dateTime).toLocaleDateString("ru-RU").slice(0, 5),
        time: new Date(event.dateTime).toLocaleTimeString("ru").slice(0, 5),
        finishTime: !event.finishTime ? "" : new Date(event.finishTime).toLocaleTimeString("ru").slice(0, 5),
        place: event.place,
        tag: event.tags,
        moderator: event.moderators?.map((mod) => ({
          name: mod,
        })),
        speakers: event.speakers?.map((speaker) => ({
          name: speaker,
        })),
        minorSpeakers: event.minorSpeakers,
        isFavorite: event.isFavorite,
      }));

      setEvents(formattedEvents);

      const dates = formattedEvents.map((event) => event.date);
      const uniqueDates = [...new Set(dates)].sort(
        (a, b) => new Date(a) - new Date(b)
      );
      setFormatedDates(uniqueDates);
    }
  }, [eventsData]);

  const groupByDate = events.reduce((acc, event) => {
    // Если в аккумуляторе уже есть запись для этой даты, добавляем к ней
    if (!acc[event.date]) {
      acc[event.date] = [];
    }
    acc[event.date].push(event);
    return acc;
  }, {});

  // if (isLoading) return <p>Загрузка...</p>;
  // if (isError) return <p>Ошибка при загрузке данных</p>;

  return (
    <MainLayout>
      <div className={styles.container} id="program">
        <SocialButtons />

        <div className={styles.column_heading}>
          <span className={styles.heading}>Программа</span>
        </div>
        <div className={ styles.calendar }>
          {
            isLoading ? <Loader></Loader> :
              <EventsCalendar
                dates={formatedDates}
                groupByDate={groupByDate}
                user={true}
                admin={ false }
              />
          }

        </div>
      </div>
    </MainLayout>
  );
}
