import React from "react";
import MainLayout from "../../components/Layout/MainLayout";
import SocialButtons from "../../components/SocialButtons/SocialButtons";
import UrbanTourProgram from "../../components/UrbanTourProgram/UrbanTourProgram";
import CardLink from "../../components/CardLink/CardLink";
import etagi from "./images/etagi.png";
import background from "./images/background-city.svg";
import backgroundMobile from "./images/backgroud-mobile.svg";
import checkmark from "./images/checkmark.svg";
import styles from "./UrbanTour.module.css";
import { useEffect } from "react";

export default function UrbanTour() {
  const listMark = <img src={checkmark} />;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainLayout>
      <div className={styles.container} >
        <SocialButtons />

        <div className={styles.block} id="tour">
          <div className={styles.column}>
            <div className={styles.row}>
              <div className={styles.heading}>
                <span>УРБАН ТУР</span>
                <span>GRADFORUM</span>
              </div>
              <div className={styles.side_img}>
                <img src={etagi} />
              </div>
            </div>
            <div className={styles.text}>
              <p>
                Тюмень смело называют столицей российского девелопмента, ведь
                именно здесь появились такие крупные федеральные застройщики
                как: «Брусника», «Страна Девелопмент», «ЭНКО» и многие другие!
              </p>
              <p>
                <span className={styles.font_weight_600}>
                  Почему стоит посетить Урбан Тур?
                </span>
                <br />
                Урбан Тур – это уникальная возможность знакомства с передовыми
                практиками в области девелоперского продукта, архитектуры и
                благоустройства. Участие в туре отличный шанс в расширении
                знаний, встреч с ведущими экспертами, а также обмена опытом с
                коллегами из разных городов нашей страны.
              </p>
              <p>
                <span className={styles.font_weight_600}>Длительность:</span> 1
                дня
                <br />
                <span className={styles.font_weight_600}>Время:</span> 6 часа
                <br />
                <span className={styles.font_weight_600}>Стоимость: </span>
                Бесплатно для всех участников форума
              </p>
              <p>
                <span className={styles.font_weight_600}>
                  Количество участников ограничено.
                </span>{" "}
                Место закрепляется после заполнения формы бронирования
                участника.
              </p>
            </div>
            <div className={styles.btn_wrapper}>
              <span
                className={styles.btn}
                onClick={() =>
                  window
                    .open(
                      "https://forms.yandex.ru/u/66d4c5942530c25fcd7069de/",
                      "_blank"
                    )
                    .focus()
                }
              >
                Забронировать место
              </span>
            </div>
          </div>
          <div className={styles.background_img}>
            <img src={background} />
          </div>
          <div className={styles.background_img_mobile}>
            <img src={backgroundMobile} />
          </div>
        </div>

        <div className={styles.container_top_border}>
          <div className={styles.heading}>
            <span>ЧТО ВКЛЮЧЕНО?</span>
          </div>
          <div className={styles.list}>
            <div className={styles.list_item}>
              {listMark}
              <label>
                Сопровождение экспертами в сфере урбанизма, продукта и
                маркетинга. Презентация от лица создателей проектов.
              </label>
            </div>
            <div className={styles.list_item}>
              {listMark}
              <label>Комфортный трансфер по городу.</label>
            </div>
            <div className={styles.list_item}>
              {listMark}
              <label>Профессиональная фотосъемка.</label>
            </div>
            <div className={styles.list_item}>
              {listMark}
              <label>
                Урбан-бук с полной информацией по проектам и общей информацией о
                городе Тюмень.
              </label>
            </div>
            <div className={styles.list_item}>
              {listMark}
              <label>Вода в дорогу.</label>
            </div>
            <div className={styles.list_item}>
              {listMark}
              <label>Удобная современная система радиогид.</label>
            </div>
          </div>
        </div>

        {/*       <div className={styles.container_top_border}>
          <div className={styles.heading}>
            <span>Программа</span>
          </div>
          <div className={styles.program_wrapper}>
            <UrbanTourProgram />
          </div>
        </div> */}
  
        <div className={styles.card_container}>
          <div
            className={styles.card}
            onClick={() => window.open("https://t.me/gradforum", "_blank")}
          >
            <CardLink />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
