import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import styles from "./QuestionAnswer.module.css";

export default function QuestionAnswer() {
  return (
    <div className={styles.container}>
      {/*---о форуме---*/}
      <div className={styles.qna_block}>
        <span>о форуме</span>
        <div className={styles.accordion}>
          <Accordion
            multiple
            activeIndex={[]}
            expandIcon="pi pi-plus"
            collapseIcon="pi pi-times"
          >
            <AccordionTab header="О чем форум?">
              <p>
                Тюменский градостроительный форум – это платформа для открытого
                диалога власти и бизнеса, представителей профессиональных
                сообществ страны, созданная с целью обсуждения вопросов развития
                городов и продвижения совместных инициатив через актуальные
                повестки деловой программы. Площадка является платформой для
                обмена знаниями и опытом с экспертами урбанистики, архитектуры и
                бизнеса. В открытом диалоге совместно находим эффективные
                решения для развития городов, создания комфортной городской
                среды, улучшая качество жизни людей.
              </p>
              <p>
                Ключевая повестка 2024 года – «Города, как платформа для инноваций:
                технологии развития городов».
              </p>
            </AccordionTab>
            <AccordionTab header="Когда и где пройдет форум?">
              <p>
                Тюменский градостроительный форум состоится 7-8 ноября 2024г., в
                историческом центре г.Тюмени, на площадке мирового уровня по
                адресу: ул. Первомайская 1А.
              </p>
            </AccordionTab>
            <AccordionTab header="Какая цель стоит перед ГРАДФОРУМОМ?">
              <p>
                Создать платформу для открытого диалога власти и бизнеса,
                представителей профессиональных сообществ для обсуждения
                вопросов развития городов и продвижения совместных инициатив.
              </p>
            </AccordionTab>
            <AccordionTab header="Кто является организаторами градфорума?">
              <p>
                Организатором форума является Главное управление строительства
                Тюменской области.
              </p>
            </AccordionTab>
          </Accordion>
        </div>
      </div>

      {/*---регистрация---*/}
      <div className={styles.qna_block}>
        <span>регистрация</span>
        <div className={styles.accordion}>
          <Accordion
            multiple
            activeIndex={[]}
            expandIcon="pi pi-plus"
            collapseIcon="pi pi-times"
          >
            <AccordionTab header="Какие форматы участия?">
              <p>
                Предоставляется выбор двух пакетов участника: Вип-участник и
                Бизнес-участник.
              </p>
            </AccordionTab>
            <AccordionTab header="Как стать участником форума?">
              <p>
                Чтобы стать участником ГРАДФОРУМА 2024 необходимо:
                <ol>
                  <li>
                    Зарегистрироваться в личном кабинете на официальном сайте
                    форума;
                  </li>
                  <li>Выбрать интересующий вас пакет участника; </li>
                  <li>Подать заявку и оплатить выбранный тариф.</li>
                </ol>
              </p>
            </AccordionTab>
          </Accordion>
        </div>
      </div>

      {/*---программа---*/}
      <div className={styles.qna_block}>
        <span>программа</span>
        <div className={styles.accordion}>
          <Accordion
            multiple
            activeIndex={[]}
            expandIcon="pi pi-plus"
            collapseIcon="pi pi-times"
          >
            <AccordionTab header="Как стать спикером форума? ">
              <p>
                Если вы эксперт в области градостроительства, архитектуры,
                урбанистики, а также смежных областей, или являетесь
                представителем бизнеса и готовы поделиться своим опытом,
                знаниями, видением будущего с широкой аудиторией профессионалов
                на темы вопросов развития городов, мы приглашаем вас
                присоединиться к нам.
              </p>
              <p>
                Для этого необходимо:
                <ol>
                  <li>
                    Отправить письмо на электронную почту e.adecova@gradforum.ru
                    с подробным описанием вашей темы, основными тезисами,
                    кратким резюме и информацией о вашем опыте.
                  </li>
                  <li>
                    Ожидать ответа от организатора – мы рассмотрим заявку и
                    обязательно с вами свяжемся.
                  </li>
                </ol>
              </p>
            </AccordionTab>
            <AccordionTab header="Какие темы будут затронуты в 2024 году?">
              <p>
                В рамках деловой программы будут затронуты темы урбанистики и
                градостроительства, развития городов и городских цифровых
                технологий.
              </p>
            </AccordionTab>
            <AccordionTab header="Кто станет хедлайнерами форума?">
              <p>
                На предстоящем форуме ожидается участие высокопоставленных
                представителей власти, которые и станут его хедлайнерами
              </p>
            </AccordionTab>
            <AccordionTab header="Будет ли организована культурная программа?">
              <p>
                Команда организаторов форума приготовила насыщенную культурную
                программу в рамках форума. Следите за новостями!
              </p>
            </AccordionTab>
          </Accordion>
        </div>
      </div>

      {/*---экспозиционная зона---*/}
      <div className={styles.qna_block}>
        <span>экспозиционная зона</span>
        <div className={styles.accordion}>
          <Accordion
            multiple
            activeIndex={[]}
            expandIcon="pi pi-plus"
            collapseIcon="pi pi-times"
          >
            <AccordionTab header="Можно ли посетить выставочную экспозицию бесплатно?">
              <p>
                Выставочная площадка доступна для посещения только участниками
                ГРАДФОРУМА.
              </p>
            </AccordionTab>
            <AccordionTab header="Где расположена выставочная экспозиция?">
              <p>
                Выставочная экспозиция будет расположена на главной площадке
                форума по адресу: г.Тюмень, ул.Первомайской 1А.
              </p>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
