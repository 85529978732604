import React, { useState, useEffect } from "react";
import { SelectButton } from "primereact/selectbutton";
import EventCard from "../../components/EventCard/EventCard";
import styles from "./EventsCalendar.module.css";
import { Link } from "react-router-dom";

export default function EventsCalendar({
  dates,
  groupByDate: events,
  user = false,
  userStatus,
  admin = false,
}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [displayedEvents, setDisplayedEvents] = useState([]);

  useEffect(() => {
    if (dates.length > 0) {
      const initialDate = dates[0];
      setSelectedDate(initialDate);
      setDisplayedEvents(
        user && userStatus
          ? filterByStatus(sortByTime(events[initialDate]), userStatus)
          : sortByTime(events[initialDate])
      );
    }
  }, [dates, events, user, userStatus]);

  function sortByTime(data) {
    return data?.sort(
      (a, b) =>
        Number(a.time.slice(0, 2)) * 60 +
        Number(a.time.slice(3, 5)) -
        (Number(b.time.slice(0, 2)) * 60 + Number(b.time.slice(3, 5)))
    );
  }

  function filterByStatus(data, userStatus) {
    return data?.filter((item) => item.status === userStatus);
  }

  /*---handle-selection---*/
  const handleDateSelection = (selected) => {
    setSelectedDate(selected);
    if (events[selected]) {
      user && userStatus
        ? setDisplayedEvents(
            filterByStatus(sortByTime(events[selected]), userStatus)
          )
        : setDisplayedEvents(sortByTime(events[selected]));
    } else setDisplayedEvents([]);
  };

  const calendarTemplate = (option) => {
    return <label className={styles.custom_chip}>{option.value}</label>;
  };

  const NoEvents = () => (
    <div className={styles.card}>
      <p>
        
      </p>
    </div>
  );

  const Calendar = () => (
    <div className={ styles.card }>
      <div className={ styles.dates }>
        <SelectButton
          value={selectedDate}
          onChange={(e) => handleDateSelection(e.value)}
          itemTemplate={calendarTemplate}
          optionLabel="value"
          options={dates.map((item) => ({ value: item }))}
          allowEmpty={false}
        />
      </div>

      <div className={styles.column}>
        {displayedEvents.length > 0 ? (
          displayedEvents?.map((item) => (
            <EventCard key={item.name} event={item} user={user} admin={admin} />
          ))
        ) : (
          <div className={styles.empty_calendar}>
            <p>На выбранную дату событий пока не запланировано.</p>
          </div>
        )}
      </div>
    </div>
  );

  return Object.keys(events).length === 0 ? <NoEvents /> : <Calendar />;
}
